<template>
    <div class="row g-0">
        <div class="col-12">
            <center>
                <h1 class='quote' v-for='message in messages' :key='message.id'>
                    "{{ message.message }}"
                </h1>
            </center>
        </div>
    </div>
</template>

<script>
import Message from '@/services/Message.js'

export default {
    name: 'Messages',
    data () {
        return {
            messages: []
        }
    },
    beforeMount() {
        Message.getAll()
        .then(res => {
            this.messages = res.data
        })
        .catch(err => {

        })
        .finally(() => {})
    }
}
</script>

<style scoped>
    .quote {
        color: #ffffff;
        font-weight: 900;
        font-size: 1.5em;
        padding-bottom: 30px;
        padding-top: 30px;
    }
    .d-md-block {
        margin-top: 0px;
        padding-top: 0px;
    }
</style>