<template>
    <div class="col-md-12">
        <img src="images/employee-page_01.gif" class="img-fluid" />
    </div>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<style scoped>
</style>