<template>
  <div class="container-fluid">
      <div class="row g-0">
        <div class="col-12">'
          <img src="images/header.png" class="img-fluid" />
        </div>
          <!--<div class="col-sm-8 col-md-6">
              <div class="row g-0">
                  <Header />
              </div>
              <div class="row g-0" style="margin-top: -20px;">
                  <Buttons />
              </div>
          </div>
          <Messages />-->
      </div>
      <Messages />
      <Buttons />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Buttons from '@/components/Buttons.vue'
import Messages from '@/components/Messages.vue'

export default {
  name: 'Main',
  components: {
    Header,
    Buttons,
    Messages
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
