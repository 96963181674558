<template>
    <div class="row g-0" style="margin-bottom: 30px;">
        <div class="col-12" style="background-color: #fff; border-radius: 15px; padding-top: 10px; padding-bottom: 30px;">
            <div class="row g-0">
                <div class="col-sm-2 col-md-1 offset-sm-1 icons">
                    <a href="https://scottelectricusa.com" target="_blank"><img class="img-fluid" src="images/se_website.png" /></a>
                </div>
                <div class="col-sm-2 col-md-1 icons">
                    <a href="https://directory.scottelectricusa.com/?user=LCSaT4UsDP0bl4&pass=S8gh9SG-----xJDLKs5yt*****WWlCXgS2YzV3T-----fIzsoUCZQ6FZJdcZZanX4L5RHgtKeZYepPsB912" target="_blank"><img class="img-fluid" src="images/directory.png" /></a>
                </div>
                <div class="col-sm-2 col-md-1 icons">
                    <a href="https://www.paycomonline.net/v4/ee/ee-takiosk.php/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJQYXljb20iLCJhdWQiOiJUYUtpb3NrIiwiY2xpZW50Y29kZSI6IjBXNDU5IiwiY2xvY2tfaWQiOiJXRUIwNiIsInNoYXJkX2lkIjoiREIzMiJ9._R-7VKs2Yab4VSRZ2NqP_XfjxYVagiq18xe8-CfnkMFJhASaiD6aB4CBmtMThyEtZdb7zjk2uY6aix6qW9-D5w" target="_blank"><img class="img-fluid" src="images/paycom_quick.png" /></a>
                </div>
                <div class="col-sm-2 col-md-1 icons">
                    <a href="https://www.paycomonline.net/v4/ee/web.php/app/login" target="_blank"><img class="img-fluid" src="images/paycom_full.png" /></a>
                </div>
                <div class="col-sm-2 col-md-1 icons">
                    <a href="https://intranet.scottelectricusa.com" target="_blank"><img class="img-fluid" src="images/hr.png" /></a>
                </div>
                <div class="col-sm-2 col-md-1 offset-sm-1 offset-md-0 icons">
                    <a href="#"><img class="img-fluid" src="images/benefits.png" /></a>
                </div>
                <div class="col-sm-2 col-md-1 icons">
                    <a href="#"><img class="img-fluid" src="images/cantread.png" /></a>
                </div>
                <div class="col-sm-2 col-md-1 icons">
                    <a href="https://scottelectricusa.com/admin" target="_blank"><img class="img-fluid" src="images/promotions.png" /></a>
                </div>
                <div class="col-sm-2 col-md-1 icons">
                    <a href="https://cp.intermedia.net/ControlPanel/Login?ClientType=WebMail" target="_blank"><img class="img-fluid" src="images/email.png" /></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Message from '@/services/Message.js'

export default {
    name: 'Buttons',
    data () {
        return {
            messages: []
        }
    },
    beforeMount() {
        Message.getAll()
        .then(res => {
            this.messages = res.data
        })
        .catch(err => {

        })
        .finally(() => {})
    }
}
</script>

<style scoped>
    .icons {
        padding-top: 10px;
        padding-right: 8px;
        padding-left: 8px;
    }
    .quote {
        color: #7e3631;
        font-weight: 900;
        font-size: 1.2em;
        padding-bottom: 20px;
    }
</style>